@import 'variables';
@import 'typography';
@import 'mixins';
@import 'responsive';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index';
@import '~sweetalert2/dist/sweetalert2.css';
@import '~vue-plyr/dist/vue-plyr.css';
@import '~leaflet/dist/leaflet.css';
@import 'transitions';
@import 'inter';

// Vue Cloak
[v-cloak] {
    display: none;
}

body {
    font-family: 'Roboto', sans-serif;
    direction: ltr;
}

.no-padding {
    padding: 0;
}

.profile-photo-preview {
    display: inline-block;
    background-position: center;
    background-size: cover;
    vertical-align: middle;
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.navbar-laravel {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.side-nav {
    height: 100vh;
    background-color: #3A4052;

    .list-group--navigation {
        .list-group-item {
            color: $primary-color;
            background-color: $third-color;

            &:hover {
                border-left: 3px solid $primary-color;
                background-color: $secondary-color;
            }

            a {
                color: $primary-color;
                font-family: $primary-font;
                font-weight: 500;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

pre {
    display: block;
    padding: 10.5px;
    margin: 0 0 11px;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    word-wrap: break-word;
    color: #333333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.plan-feature-list {
    list-style: none;
}

.plan-feature-list li {
    line-height: 25px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.spark-profile-photo-xs {
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.spark-profile-photo {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.spark-profile-photo-xl {
    height: 125px;
    width: 125px;
    border-radius: 50%;
}

.list-group {
    &--navigation {
        .list-group-item {
            border-radius: 0;

            span {
                font-family: $font-family-sans-serif;
            }
        }
    }
}

// AA //
.navbar {
    border-bottom: 1px solid $gray-300;
}

.tool {
    color: $secondary-color;

    & > &-header {
        padding: 1.5rem 2rem;
        background-color: #fff;
        margin: -15px -15px 0 -15px;

        h2 {
            color: $secondary-color;
        }

        span {
            margin-right: 10px;
        }

        small {
            color: $text-gray;
        }
    }

    & > &-body {
        padding: 1.5rem 2rem;

        & > .card {
            &-header {
                color: $primary-color;
                font-weight: bold;
                border-radius: 5px 5px 0 0;
                background-color: $secondary-color;

                &__primary {
                    background-color: $primary-color;
                    color: #fff;
                }
            }

            &-body {
                input,
                select {
                    padding-left: 0;
                    outline: none;
                    border-width: 0 0 2px;
                    border-color: $primary-color;
                    box-shadow: none;
                    background-color: transparent;

                    &:focus {
                        outline: none;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: $text-gray;
                        opacity: 1;
                    }
                }

                label {
                    color: $text-gray;
                    font-weight: 400;
                }

                button {
                    background: $primary-color;
                    border: none;
                    border-radius: 2px;
                }
            }

            .btn {
                min-width: 100px;
                border: none;
                border-radius: 5px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);

                &-primary {
                    background: $primary-color;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }

                &-danger {
                    background: $danger-red;

                    &:hover {
                        background-color: $secondary-color;
                    }
                }
            }

            .range-slider {
                -webkit-appearance: none;
                width: $range-width;
                height: $range-track-height;
                border-radius: 5px;
                background: $primary-color;
                outline: none;
                padding: 0;
                margin: 0;

                // Range Handle
                &::-webkit-slider-thumb {
                    appearance: none;
                    width: $range-handle-size;
                    height: $range-handle-size;
                    border-radius: 50%;
                    background: $primary-color;
                    cursor: pointer;
                    transition: background .15s ease-in-out;

                    &:hover {
                        background: $primary-color;
                    }
                }

                &:active::-webkit-slider-thumb {
                    background: $primary-color;
                }

                &::-moz-range-thumb {
                    width: $range-handle-size;
                    height: $range-handle-size;
                    border: 0;
                    border-radius: 50%;
                    background: $primary-color;
                    cursor: pointer;
                    transition: background .15s ease-in-out;

                    &:hover {
                        background: $primary-color;
                    }
                }

                &:active::-moz-range-thumb {
                    background: $primary-color;
                }
            }
        }
    }
}

.icon-30 {
    width: 30px;
    height: 30px;
}

.icon-25 {
    width: 25px;
    height: 25px;
}

.icon-20 {
    width: 20px;
    height: 20px;
}

.icon-15 {
    width: 15px;
    height: 15px;
}

.shadow-custom {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
}

.nav-profile-photo {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.h-37 {
    height: 37px;
}

.navbar-toolsuite {
    background-color: $white;

    .navbar-nav {
        .nav-link {
            color: #49545a;
            display: flex;
            align-items: center;
            font-size: 1rem;
            font-weight: 600;
            padding: 5px 0.5rem;
        }
    }
}

.settings-tabs {
    .nav-link {
        display: flex;
        align-items: center;
        padding: 5px 0 10px 0;
        font-size: 1rem;
        font-weight: 600;
        color: $nav-link-color;

        svg {
            @extend .icon-20;

            fill: $nav-link-color;
            margin-right: 15px;
            flex-shrink: 0;
        }

        &:hover {
            color: $primary;

            svg {
                fill: $primary;
            }
        }

        &.active {
            color: $primary;

            svg {
                fill: $primary;
            }
        }
    }
}

.nav-heading {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 14px;
    color: $gray-500;
    letter-spacing: 1px;
    padding: 0;
    margin-bottom: 10px;
}

.card-stack {
    & .card {
        margin-bottom: 2rem;
    }
}

.notification-pill {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    width: 4.4375rem;
    height: 2.125rem;
    background-color: #f4f5f6;
    color: #49545a;
    font-weight: 600;
    font-size: .9375rem;
}

.btn-default {
    background-color: #fff;
    border-color: #c7cdd1;
    color: #5c6870;
    font-weight: 300;
    font-size: 1rem;
}

.profile-photo {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}
