<script>
import { mapGetters } from 'vuex'
import { CoinsIcon } from 'lucide-vue'

export default {
    name: 'Settings',
    components: {
        CoinsIcon,
    },
    computed: {
        ...mapGetters({
            'user': 'auth/user',
            'team': 'auth/team',
            'teams': 'auth/teams',
        }),

        selectedTeam() {
            return this.teams.find(team => team.id === Number(this.$route.params.id))
        },
    },
}
</script>

<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <strong class="ml-2">{{ selectedTeam.name }}</strong>
                </div>

            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div class="d-flex align-items-center ">
                        <CoinsIcon />
                        <strong class="ml-2">Remaining credits:&nbsp;</strong> {{ selectedTeam.remaining_actions }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
