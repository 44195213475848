
@import '../../sass/variables';

.card {

    &-body {
        text-align: center;

        table {
            thead {
                color: $secondary-color;
            }

            button {
                background: $danger-red;

                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }
}
