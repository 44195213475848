
@import '../../sass/variables';

.smooth-enter-active,
.smooth-leave-active {
    transition: opacity .5s;
}

.smooth-enter,
.smooth-leave-to {
    opacity: 0;
}

.side-nav {
    display: flex;
    flex-flow: column;
    background-color: $third-color;

    .navbar-brand {
        display: flex;
        justify-content: center;
        margin-right: 0;
        min-width: 100%;
        min-height: 61px;
        background-color: $secondary-color;

        img {
            display: flex;
            align-self: center;
        }
    }

    .navbar-items {
        display: flex;
        flex: 1;
        flex-flow: column;
        justify-content: space-between;

        .list-group {
            &:nth-child(2) {
                &::before {
                    content: "";
                    display: block;
                    margin: 0 auto;
                    width: 90%;
                    padding-bottom: 15px;
                    border-top: 2px solid $primary-color;
                }
            }

            ul .list-group-item {
                display: flex;
            }
        }
    }

    .list-group--navigation {
        .list-group-item {
            color: $primary-color;
            background-color: $third-color;
            border: none;
            border-left: 3px solid transparent;
            transition: background-color 0.3s, border-left 0.3s;

            &:hover {
                border-left: 3px solid $primary-color;
                background-color: $secondary-color;
            }

            span {
                color: $primary-color;
                font-family: $primary-font;
                font-weight: 500;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .active {
            border: none;
            border-left: 3px solid $primary-color;
            background-color: $secondary-color;
        }
    }
}
