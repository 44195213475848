
@import '../../sass/variables';

.btn {
    min-width: 100px;
    margin: 50px 0 25px 0;
    border: none;
    border-radius: 5px;

    &-primary {
        background: $primary-color;
    }

    &-danger {
        background: $danger-red;
    }
}
