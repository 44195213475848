<style scoped>
    .action-link {
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div v-if="tokens.length > 0">
            <div class="card card-default">
                <div class="card-header">Authorized Applications</div>

                <div class="card-body">
                    <!-- Authorized Tokens -->
                    <table class="table table-borderless mb-0">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Scopes</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="token in tokens">
                            <!-- Client Name -->
                            <td style="vertical-align: middle;">
                                {{ token.client.name }}
                            </td>

                            <!-- Scopes -->
                            <td style="vertical-align: middle;">
                                <span v-if="token.scopes.length > 0">
                                    {{ token.scopes.join(', ') }}
                                </span>
                            </td>

                            <!-- Revoke Button -->
                            <td style="vertical-align: middle;">
                                <a class="action-link text-danger" @click="revoke(token)">
                                    Revoke
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'AuthorizedClients',
        /*
         * The component's data.
         */
        data () {
            return {
                tokens: []
            }
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        async mounted () {
            await this.prepareComponent()
        },

        methods: {
            /**
             * Prepare the component (Vue 2.x).
             */
            async prepareComponent () {
                await this.getTokens()
            },

            /**
             * Get all of the authorized tokens for the user.
             */
            async getTokens () {
                const { data } = await axios.get('/api/v1/oauth/tokens')
                this.tokens = data
            },

            /**
             * Revoke the given token.
             */
            async revoke (token) {
                await axios.delete('/api/v1/oauth/tokens/' + token.id)

                await this.getTokens()
            }
        }
    }
</script>
