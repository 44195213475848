
@import '../../sass/variables';

.tool {
    height: 100%;

    &-body {
        .btn {
            min-width: 100px;
            margin: 50px 0 25px 0;
            border: none;
            border-radius: 5px;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);

            &-primary {
                background: $primary-color;

                &:hover {
                    background-color: $secondary-color;
                }
            }

            &-danger {
                background: $danger-red;

                &:hover {
                    background-color: $secondary-color;
                }
            }
        }
    }

    .card {
        height: 100%
    }
}
