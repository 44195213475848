
@import '../../../sass/variables';

.profile-finder-results {
    .card {
        &-header {
            color: $white;
            background: $primary-color;

            .badge {
                &-secondary {
                    color: $white;
                    background-color: transparent;
                }
            }
        }

        &-body {
            .container-fluid {
                .card-header {
                    background: $secondary-color;
                }
            }
        }
    }
}

.result-image {
    display: flex;
    justify-content: flex-start;

    .card {
        width: 100px;
        margin-right: 25px;
    }
}
