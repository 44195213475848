
@import '../../../sass/variables';

.card {
    font-weight: bold;

    &-header {
        color: $secondary-color;
        background-color: transparent;
        border-bottom: 2px solid $primary-color;

        a {
            text-decoration: none;
            color: $secondary-color;
        }
    }

    &-body {
        span {
            font-weight: 400;
        }
    }
}

.greyed-out {
    color: $text-gray;
}
