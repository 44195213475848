<style scoped>
    .action-link {
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div>
            <b-card class=" card-default">
                <template v-slot:header>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>
                            Personal Access Tokens
                        </span>

                        <a class="action-link" tabindex="-1" @click="showCreateTokenForm">
                            Create New Token
                        </a>
                    </div>
                </template>

                <!-- No Tokens Notice -->
                <p class="mb-0" v-if="tokens.length === 0">
                    You have not created any personal access tokens.
                </p>

                <!-- Personal Access Tokens -->
                <table class="table table-borderless mb-0" v-if="tokens.length > 0">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="token in tokens">
                        <!-- Client Name -->
                        <td style="vertical-align: middle;">
                            {{ token.name }}
                        </td>

                        <!-- Delete Button -->
                        <td style="vertical-align: middle;">
                            <a class="action-link text-danger" @click="revoke(token)">
                                Delete
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-card>
        </div>

        <!-- Create Token Modal -->
        <b-modal v-model="modalCreateToken">
            <template v-slot:header>
                <h4 class="modal-title">
                    Create Token
                </h4>
            </template>
            <!-- Form Errors -->
            <b-alert variant="danger" :dismissible="false" v-if="form.errors.length > 0">
                <p class="mb-0"><strong>Whoops!</strong> Something went wrong!</p>
                <br>
                <ul>
                    <li v-for="error in form.errors">
                        {{ error }}
                    </li>
                </ul>
            </b-alert>

            <!-- Create Token Form -->
            <b-form @submit.prevent="store">
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-4 col-form-label">Name</label>

                    <div class="col-md-6">
                        <input id="create-token-name" type="text" class="form-control" name="name"
                               v-model="form.name">
                    </div>
                </div>

                <!-- Scopes -->
                <div class="form-group row" v-if="scopes.length > 0">
                    <label class="col-md-4 col-form-label">Scopes</label>

                    <div class="col-md-6">
                        <div v-for="scope in scopes">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           @click="toggleScope(scope.id)"
                                           :checked="scopeIsAssigned(scope.id)">

                                    {{ scope.id }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </b-form>

            <!-- Modal Actions -->
            <template v-slot:modal-footer>
                <a href="#" class="btn btn-secondary" @click.prevent="modalCreateToken = false">Close</a>

                <b-btn href="#" variant="primary" @click.prevent="store">
                    Create
                </b-btn>
            </template>
        </b-modal>

        <!-- Access Token Modal -->
        <b-modal v-model="modalAccessToken">
            <template v-slot:header>
                <h4 class="modal-title">
                    Personal Access Token
                </h4>
            </template>
            <p>
                Here is your new personal access token. This is the only time it will be shown so don't lose
                it!
                You may now use this token to make API requests.
            </p>

            <textarea class="form-control" rows="10">{{ accessToken }}</textarea>

            <!-- Modal Actions -->
            <template v-slot:footer>
                <b-button variant="secondary">Close</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { flatten, indexOf, reject, toArray } from 'lodash'
    import axios from 'axios'

    export default {
        name: 'PersonalAccessTokens',
        /*
         * The component's data.
         */
        data () {
            return {
                accessToken: null,

                tokens: [],
                scopes: [],

                form: {
                    name: '',
                    scopes: [],
                    errors: []
                },

                modalCreateToken: false,
                modalAccessToken: false
            }
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        async mounted () {
            await this.prepareComponent()
        },

        methods: {
            /**
             * Prepare the component.
             */
            async prepareComponent () {
                await this.getTokens()
                await this.getScopes()
            },

            /**
             * Get all of the personal access tokens for the user.
             */
            async getTokens () {
                const { data } = await axios.get('/api/v1/oauth/personal-access-tokens')

                this.tokens = data
            },

            /**
             * Get all of the available scopes.
             */
            async getScopes () {
                const { data } = await axios.get('/api/v1/oauth/scopes')

                this.scopes = data
            },

            /**
             * Show the form for creating new tokens.
             */
            showCreateTokenForm () {
                this.modalCreateToken = true
            },

            /**
             * Create a new personal access token.
             */
            async store () {
                this.accessToken = null

                this.form.errors = []

                try {
                    const { data } = await axios.post('/api/v1/oauth/personal-access-tokens', this.form)
                    this.form.name = ''
                    this.form.scopes = []
                    this.form.errors = []

                    this.tokens.push(data.token)

                    this.showAccessToken(data.accessToken)
                } catch (error) {
                    if (typeof error.response.data === 'object') {
                        this.form.errors = flatten(toArray(error.response.data.errors))
                    } else {
                        this.form.errors = ['Something went wrong. Please try again.']
                    }
                }
            },

            /**
             * Toggle the given scope in the list of assigned scopes.
             */
            toggleScope (scope) {
                if (this.scopeIsAssigned(scope)) {
                    this.form.scopes = reject(this.form.scopes, s => s === scope)
                } else {
                    this.form.scopes.push(scope)
                }
            },

            /**
             * Determine if the given scope has been assigned to the token.
             */
            scopeIsAssigned (scope) {
                return indexOf(this.form.scopes, scope) >= 0
            },

            /**
             * Show the given access token to the user.
             */
            showAccessToken (accessToken) {
                this.modalCreateToken = false

                this.accessToken = accessToken

                this.modalAccessToken = true
            },

            /**
             * Revoke the given token.
             */
            async revoke (token) {
                await axios.delete('/api/v1/oauth/personal-access-tokens/' + token.id)

                await this.getTokens()
            }
        }
    }
</script>
