@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700');

body {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

p {
    font-size: 1rem;
    line-height: 1.750em;

}

h1 {
    font-size: 2.25rem;
    line-height: 1.33;
}

h2 {
    font-size: 1.875rem;
    line-height: 1.6;
}

h3 {
    font-size: 1.625rem;
    line-height: 1.8461538461538463em;
}

h4 {
    font-size: 1.375rem;
    line-height: 2.182em;
    font-weight: 600;
}
