<style scoped>
    .action-link {
        cursor: pointer;
    }
</style>

<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>
                        OAuth Clients
                    </span>

                    <a href="#" class="action-link" tabindex="-1" @click.prevent="showCreateClientForm">
                        Create New Client
                    </a>
                </div>
            </div>

            <div class="card-body">
                <!-- Current Clients -->
                <p class="mb-0" v-if="clients.length === 0">
                    You have not created any OAuth clients.
                </p>

                <table class="table table-borderless mb-0" v-if="clients.length > 0">
                    <thead>
                    <tr>
                        <th>Client ID</th>
                        <th>Name</th>
                        <th>Secret</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr v-for="client in clients">
                        <!-- ID -->
                        <td style="vertical-align: middle;">
                            {{ client.id }}
                        </td>

                        <!-- Name -->
                        <td style="vertical-align: middle;">
                            {{ client.name }}
                        </td>

                        <!-- Secret -->
                        <td style="vertical-align: middle;">
                            <code>{{ client.secret }}</code>
                        </td>

                        <!-- Edit Button -->
                        <td style="vertical-align: middle;">
                            <a class="action-link" tabindex="-1" @click="edit(client)">
                                Edit
                            </a>
                        </td>

                        <!-- Delete Button -->
                        <td style="vertical-align: middle;">
                            <a class="action-link text-danger" @click="destroy(client)">
                                Delete
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Create Client Modal -->
        <b-modal v-model="modalCreateClient">
            <template v-slot:header>
                <h4 class="modal-title">
                    Create Client
                </h4>
            </template>
            <!-- Form Errors -->
            <b-alert variant="danger" :dismissible="false" v-if="createForm.errors.length > 0">
                <p class="mb-0"><strong>Whoops!</strong> Something went wrong!</p>
                <br>
                <ul>
                    <li v-for="error in createForm.errors">
                        {{ error }}
                    </li>
                </ul>
            </b-alert>

            <!-- Create Client Form -->
            <b-form>
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name</label>

                    <div class="col-md-9">
                        <input id="create-client-name" type="text" class="form-control"
                               @keyup.enter="store" v-model="createForm.name">

                        <span class="form-text text-muted">
                            Something your users will recognize and trust.
                        </span>
                    </div>
                </div>

                <!-- Redirect URL -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Redirect URL</label>

                    <div class="col-md-9">
                        <input type="text" class="form-control" name="redirect"
                               @keyup.enter="store" v-model="createForm.redirect">

                        <span class="form-text text-muted">
                            Your application's authorization callback URL.
                        </span>
                    </div>
                </div>
            </b-form>

            <!-- Modal Actions -->
            <template v-slot:footer>
                <a href="#" class="btn btn-secondary" @click.prevent="modalCreateClient = false">Close</a>

                <b-button variant="primary" @click.prevent="store">
                    Create
                </b-button>
            </template>
        </b-modal>

        <!-- Edit Client Modal -->
        <b-modal v-model="modalEditClient">
            <template v-slot:header>
                <h4 class="modal-title">
                    Edit Client
                </h4>
            </template>

            <b-alert variant="danger" :dismissible="false" v-if="editForm.errors.length > 0">
                <p class="mb-0"><strong>Whoops!</strong> Something went wrong!</p>
                <br>
                <ul>
                    <li v-for="error in editForm.errors">
                        {{ error }}
                    </li>
                </ul>
            </b-alert>

            <!-- Edit Client Form -->
            <b-form>
                <!-- Name -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Name</label>

                    <div class="col-md-9">
                        <input id="edit-client-name" type="text" class="form-control"
                               @keyup.enter="update" v-model="editForm.name">

                        <span class="form-text text-muted">
                            Something your users will recognize and trust.
                        </span>
                    </div>
                </div>

                <!-- Redirect URL -->
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Redirect URL</label>

                    <div class="col-md-9">
                        <input type="text" class="form-control" name="redirect"
                               @keyup.enter="update" v-model="editForm.redirect">

                        <span class="form-text text-muted">
                            Your application's authorization callback URL.
                        </span>
                    </div>
                </div>
            </b-form>

            <!-- Modal Actions -->
            <template v-slot:footer>
                <a href="#" class="btn btn-secondary" @click.prevent="modalEditClient = false">Close</a>

                <b-button variant="primary" @click.prevent="update">
                    Save Changes
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import { flatten, toArray } from 'lodash'
    import axios from 'axios'

    export default {
        name: 'Clients',
        /*
         * The component's data.
         */
        data () {
            return {
                clients: [],

                createForm: {
                    errors: [],
                    name: '',
                    redirect: ''
                },

                editForm: {
                    errors: [],
                    name: '',
                    redirect: ''
                },

                modalCreateClient: false,
                modalEditClient: false
            }
        },

        /**
         * Prepare the component (Vue 2.x).
         */
        async mounted () {
            await this.prepareComponent()
        },

        methods: {
            /**
             * Prepare the component.
             */
            async prepareComponent () {
                await this.getClients()
            },

            /**
             * Get all of the OAuth clients for the user.
             */
            async getClients () {
                const { data } = await axios.get('/api/v1/oauth/clients')

                this.clients = data
            },

            /**
             * Show the form for creating new clients.
             */
            showCreateClientForm () {
                this.modalCreateClient = true
            },

            /**
             * Create a new OAuth client for the user.
             */
            async store () {
                try {
                    await this.persistClient(
                        'post', '/api/v1/oauth/clients',
                        this.createForm
                    )

                    this.modalCreateClient = false
                } catch (e) {
                    console.error(e)
                }
            },

            /**
             * Edit the given client.
             */
            edit (client) {
                this.editForm.id = client.id
                this.editForm.name = client.name
                this.editForm.redirect = client.redirect

                this.modalEditClient = true
            },

            /**
             * Update the client being edited.
             */
            async update () {
                await this.persistClient(
                    'put', '/api/v1/oauth/clients/' + this.editForm.id,
                    this.editForm
                )

                this.modalEditClient = false
            },

            /**
             * Persist the client to storage using the given form.
             */
            async persistClient (method, uri, form) {
                form.errors = []

                try {
                    await axios[method](uri, form)
                    await this.getClients()

                    form.name = ''
                    form.redirect = ''
                    form.errors = []
                } catch (error) {
                    if (typeof error.response.data === 'object') {
                        form.errors = flatten(toArray(error.response.data.errors))
                    } else {
                        form.errors = ['Something went wrong. Please try again.']
                    }

                    throw Error(error)
                }
            },

            /**
             * Destroy the given client.
             */
            async destroy (client) {
                await axios.delete('/api/v1/oauth/clients/' + client.id)

                await this.getClients()
            }
        }
    }
</script>
