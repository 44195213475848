
@import '../../sass/variables';

.instruction-duration {
    position: relative;

    span {
        position: absolute;
        right: 9px;
        top: 0;
        color: $text-gray;
    }
}
