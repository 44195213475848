
@import '../../../sass/variables';

.btn {
    &-new-entry {
        position: absolute;
        right: 10px;
        top: 0;
        color: $primary-color;
    }
}
