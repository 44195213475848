/* =================================== */
/* tailwind setup for pfp2 components  */
/* NOTES:                              */
/*  - use "prime" instead of "primary" */
/* =================================== */

@tailwind base;
@tailwind components;
@tailwind utilities;
    
:root {
    --pfp2-background: 0 0% 100%;
    --pfp2-foreground: 240 10% 3.9%;
    
    --pfp2-card: 0 0% 100%;
    --pfp2-card-foreground: 240 10% 3.9%;
    
    --pfp2-popover: 0 0% 100%;
    --pfp2-popover-foreground: 240 10% 3.9%;
    
    --pfp2-prime: 240 5.9% 10%;
    --pfp2-prime-foreground: 0 0% 98%;
    
    --pfp2-secondary: 240 4.8% 95.9%;
    --pfp2-secondary-foreground: 240 5.9% 10%;
    
    --pfp2-muted: 240 4.8% 95.9%;
    --pfp2-muted-foreground: 240 3.8% 46.1%;
    
    --pfp2-accent: 240 4.8% 95.9%;
    --pfp2-accent-foreground: 240 5.9% 10%;
    
    --pfp2-destructive: 0 84.2% 60.2%;
    --pfp2-destructive-foreground: 0 0% 98%;
    
    --pfp2-border:240 5.9% 90%;
    --pfp2-input:240 5.9% 90%;
    --pfp2-ring:240 5.9% 10%;
    --pfp2-radius: 0.5rem;
}
    
.tw-dark {
    --pfp2-background:240 10% 3.9%;
    --pfp2-foreground:0 0% 98%;
    
    --pfp2-card:240 10% 3.9%;
    --pfp2-card-foreground:0 0% 98%;
    
    --pfp2-popover:240 10% 3.9%;
    --pfp2-popover-foreground:0 0% 98%;
    
    --pfp2-prime:0 0% 98%;
    --pfp2-prime-foreground:240 5.9% 10%;
    
    --pfp2-secondary:240 3.7% 15.9%;
    --pfp2-secondary-foreground:0 0% 98%;
    
    --pfp2-muted:240 3.7% 15.9%;
    --pfp2-muted-foreground:240 5% 64.9%;
    
    --pfp2-accent:240 3.7% 15.9%;
    --pfp2-accent-foreground:0 0% 98%;
    
    --pfp2-destructive:0 62.8% 50.6%;
    --pfp2-destructive-foreground:0 0% 98%;
    
    --pfp2-border:240 3.7% 20.9%;
    --pfp2-input:240 3.7% 20.9%;
    --pfp2-ring:240 4.9% 83.9%;
}

@layer base {
    @font-face {
        font-family: 'Inter var';
        src: url('/assets/font/InterVariable.woff2') format('woff2');
    }
    
    * {
        @apply tw-border-border;
    }
    
    body.pfp2 {
        @apply tw-bg-[#F3F4F6] tw-text-foreground tw-antialiased;

        &.tw-dark {
            @apply tw-bg-[#050a17];
        }

        * {
            @apply tw-font-inter;
        }

        p {
            margin-bottom: 0;
        }
    }
}
