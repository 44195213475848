
@import '../../sass/variables';

.primary-color {
    background-color: $primary-color;
    color: $white;
}

.btn-circle {
    padding: 10px 14px;
    border: none;
    border-radius: 50%;
    color: $white;
    background-color: $primary-color;
}

.jobs-filter {
    font-weight: 400;
    margin-block-end: 0;
    padding-inline-start: 0;
    border-bottom: 1px solid $white;

    li {
        display: inline-block;
        margin-right: 40px;
        position: relative;
        padding-bottom: 6px;

        a {
            color: $white;
            text-decoration: none;
        }

        .selected-filter {
            &::after {
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                bottom: -3px;
                height: 6px;
                background-color: $white;
                border-radius: 3px;
            }
        }
    }
}
