
.tic-title {
    display: flex;
    flex-direction: column;

    &__date {
        font-size: 18px;
    }

    &__time {
        font-size: 14px;
        margin-top: 2px;
        margin-left: 1px;
        font-weight: bold;
        color: #71717a;
    }
}

.tic-download {
    background-color: #22c0e8;
    font-weight: medium;

    &:hover {
        background-color: #34b4d4;
        color: white;
        text-decoration: none;
    }
}

.tic-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    width: 100%;

    &:hover {
        background-color: #e9ebed;
    }

    &:not(:last-child) {
        &::after {
            content: '';
            background-color: #c8c8d1;
            position: absolute;
            width: calc(100% - 26px);
            height: 1px;
            bottom: 0;
        }
    }

    &::before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #22c0e8;
        left: -9px;
        margin-top: 10px;
        top: 0;
        width: 20px;
        height: 20px;
        z-index: 400;
    }
}
