
.full-height {
    min-height: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.flex-fill {
    flex: 1;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.text-center {
    text-align: center;
}

.links {
    padding: 1em;
    text-align: right;

    a {
        background-color: #3097D1;
        border: 0;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        padding: 15px;
        text-transform: uppercase;
        width: 100px;
        text-decoration: none;
        display: inline-block;
        text-align: center;
    }
}
